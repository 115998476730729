.Circle6 {
    display: none;
    background: linear-gradient(rgba(0, 235, 170, .25), rgba(94, 103, 223, .25));
    border-radius: 50%;
    position: absolute;

    &:after {
        border-radius: 50%;
        position: absolute;
        content: "";
        display: block;
        left: 8px;
        top: 8px;
        right: 8px;
        bottom: 8px;
        background: linear-gradient(#00ebaa, #5e67df);
    }
}

@media screen and (min-width: 768px) {
    .Circle6 {
        display: block;
        width: 52px;
        height: 52px;
        bottom: -57px;
        right: -59px;
        opacity: .5
    }
}