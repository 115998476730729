.Circle1 {
    border-radius: 50%;
    background: linear-gradient(rgba(0, 235, 170, .25), rgba(94, 103, 223, .25));
    position: absolute;
    left: -18px;
    top: -72px;
    opacity: .5;

    &:after {
        content: "";
        display: block;
        border-radius: 50%;
        position: absolute;
        left: 8px;
        top: 8px;
        right: 8px;
        bottom: 8px;
        background: linear-gradient(#00ebaa, #5e67df)
    }
}

@media screen and (min-width: 992px) {
    .Circle1 {
        width: 62px;
        height: 62px;
        left: -143px;
        top: 62px
    }
}