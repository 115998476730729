.Technologies {
    position: relative;
    display: flex;
    flex-direction: column;
}

.Technologies_List {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1rem;
    line-height: 2.4rem;
    color: #fff;

    & li {
        align-items: center;
        width: 120px;
        margin: 24px 23px 5px
    }
}

@media screen and (min-width: 768px) {
    .Technologies_List {
        max-width: 85%;
        margin: 0 auto;

        & li {
            margin: 24px 40px 12px
        }
    }
}

@media screen and (min-width: 768px) and (min-width:768px) {
    .Technologies_List {
        & li {
            margin: 24px 20px 12px
        }
    }
}

@media screen and (min-width: 768px) and (min-width:1200px) {
    .Technologies_List {
        & li {
            margin: 24px 62px 12px
        }
    }
}