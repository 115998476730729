.Header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;

    &Fixed {
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
}