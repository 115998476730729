.Circle2 {
    border-radius: 50%;
    background: linear-gradient(rgba(0, 235, 170, .25), rgba(94, 103, 223, .25));
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    top: 127px
}


@media screen and (min-width: 992px) {
    .Circle2 {
        width: 45px;
        height: 44px;
        right: -182px;
        top: 12px
    }
}