.Circle3 {
    width: 20px;
    height: 20px;
    left: 20px;
    top: -34px;
    background: linear-gradient(rgba(0, 235, 170, .25), rgba(94, 103, 223, .25));
    border-radius: 50%;
    position: absolute;

    &:after {
        border-radius: 50%;
        position: absolute;
        content: "";
        display: block;
        left: 8px;
        top: 8px;
        right: 8px;
        bottom: 8px;
        background: linear-gradient(#00ebaa, #5e67df);

        &:after {
            left: 4px;
            right: 4px;
            top: 4px;
            bottom: 4px
        }
    }
}

@media screen and (min-width: 768px) {
    .Circle3 {
        width: 40px;
        height: 40px;
        left: -4px;
        top: 30px;

        &:after:after {
            left: 8px;
            right: 8px;
            top: 8px;
            bottom: 8px
        }
    }
}