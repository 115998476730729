.Subtitle {
    text-transform: uppercase;
    position: relative;
    color: #00ebaa;
    display: inline-block;
    opacity: 0;

    &:global(.aos-animate) {
        opacity: 1;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            display: block;
            background: #00ebaa;
            width: calc(100% + 6px);
            animation: showSubtitle 1s ease forwards;
        }
    }
}

@keyframes showSubtitle {
    0% {
        transform: scaleX(0);
        left: -3px;
        right: auto;
        transform-origin: 0 0
    }

    49% {
        transform: scaleX(1);
        left: -3px;
        right: auto;
        transform-origin: 0 0
    }

    51% {
        transform: scaleX(1);
        transform-origin: 100% 100%;
        left: auto;
        right: -3px
    }

    to {
        transform: scaleX(0);
        transform-origin: 100% 100%;
        left: auto;
        right: -3px
    }
}