.Circle5 {
    width: 24px;
    height: 24px;
    right: 36px;
    bottom: -14px;
    opacity: .5;
    background: linear-gradient(rgba(0, 235, 170, .25), rgba(94, 103, 223, .25));
    border-radius: 50%;
    position: absolute;

    &:after {
        border-radius: 50%;
        position: absolute;
        content: "";
        display: block;
        left: 8px;
        top: 8px;
        right: 8px;
        bottom: 8px;
        background: linear-gradient(#00ebaa, #5e67df);
        &:after {
            left: 4px;
            right: 4px;
            top: 4px;
            bottom: 4px
        }
    }
}

@media screen and (min-width: 768px) {
    .Circle5 {
        right: -66px;
        top: 103px;
        opacity: 1
    }
}