.Footer {
    background: #000;
    padding: 17px 0 32px;
    font-size: 1rem;
    line-height: 2.4rem;
    color: #b2b2b2;
    text-align: center
}

.Footer_Copyright {
    margin: 0;
    text-align: center
}

@media screen and (min-width: 768px) {
    .Footer_Copyright br {
        display: none
    }
}