.Hire {
    padding: 40px 0 200px
}

@media screen and (min-width: 992px) {
    .Hire {
        padding-top: 54px
    }
}

.Hire_Circle {
    width: 168px;
    height: 168px;
    margin: 0 auto;
    background: linear-gradient(rgba(0, 235, 170, .1), rgba(94, 103, 223, .1));
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    opacity: 0;


    .Hire_Circle_Inner {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 116px;
        height: 116px;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 50%;
        background: linear-gradient(rgba(0, 235, 170, .25), rgba(94, 103, 223, .25));

        &:after {
            content: "";
            top: 20px;
            left: 20px;
            right: 20px;
            bottom: 20px;
            box-shadow: 0 0 120px 0 #00ebaa;
            display: block;
            border-radius: 50%;
            position: absolute;
            background: linear-gradient(#00ebaa, #5e67df)
        }
    }

    svg {
        position: relative;
        z-index: 30
    }

    &:global(.aos-animate) {
        animation: zoomIn .9s ease-in-out 200ms forwards
    }
}



@media screen and (max-width: 991px) {
    .Hire_Circle {
        svg {
            width: 52px
        }
    }
}


.Hire_List {
    margin: 0 auto;
    text-align: center;
    position: absolute;
    width: 310px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .Hire_List_Title {
        font-size: 1.1rem;
        line-height: 2.4rem;
        letter-spacing: .09px;
        margin: 10px 0 8px;
        opacity: 0;
    }

    .Hire_List_Desc {
        display: none;
    }

    &:global(.aos-animate) {
        &:after {
            animation: spinIn 2s ease forwards;
        }

        .Hire_List_Title,
        .Hire_List_Desc,
        li svg,
        li:after {
            animation: list .6s ease forwards
        }

        li:first-child {
            svg {
                animation-delay: .8s
            }

            .Hire_List_Title {
                animation-delay: 1s
            }

            .Hire_List_Desc {
                animation-delay: 1.2s
            }

            &:after {
                animation-delay: 1.6s
            }
        }

        li:nth-child(2) {
            svg {
                animation-delay: 1.8s
            }

            .Hire_List_Title {
                animation-delay: 2s
            }

            .Hire_List_Desc {
                animation-delay: 2.2s
            }

            &:after {
                animation-delay: 2.4s
            }
        }

        li:nth-child(3) {
            svg {
                animation-delay: 2.6s
            }

            .Hire_List_Title {
                animation-delay: 2.8s
            }

            .Hire_List_Desc {
                animation-delay: 3s
            }

            &:after {
                animation-delay: 3.2s
            }
        }

        li:nth-child(4) {
            svg {
                animation-delay: 3.4s
            }

            .Hire_List_Title {
                animation-delay: 3.6s
            }

            .Hire_List_Desc {
                animation-delay: 3.8s
            }

            &:after {
                animation-delay: 4s
            }
        }

        li:nth-child(5) {
            svg {
                animation-delay: 4.2s
            }

            .Hire_List_Title {
                animation-delay: 4.4s
            }

            .Hire_List_Desc {
                animation-delay: 4.6s
            }

            &:after {
                animation-delay: 4.8s
            }

        }

        li:nth-child(6) {
            svg {
                animation-delay: 5s
            }

            .Hire_List_Title {
                animation-delay: 5.2s
            }

            .Hire_List_Desc {
                animation-delay: 5.4s
            }

            &:after {
                animation-delay: 5.6s
            }

        }
    }

    &:after {
        content: "";
        width: 620px;
        height: 610px;
        background: url(../../../assets/static/hero-circles.png) no-repeat 50%;
        background-size: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        transform: translate(-50%, -50%);
        z-index: -1;
        opacity: 0;
    }

    li {
        position: relative;
        z-index: 11;
        position: absolute;

        svg {
            opacity: 0;
            margin-bottom: -3px;
        }

        &:after {
            opacity: 0
        }

        &:first-child {
            top: -165px;
            left: 122px;

            &:after {
                content: "";
                width: 28px;
                height: 15px;
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMjYuMDQgOC42ODFhLjMyNi4zMjYgMCAwMS42NDQtLjA4MmwuMDA4LjA1OC4xOTcgNS4zMjNhLjMyNi4zMjYgMCAwMS0uMjc2LjMzM2wtLjA2Mi4wMDQtNS4zNC0uMTk4YS4zMjYuMzI2IDAgMDEtLjAzNi0uNjQ3bC4wNi0uMDAzIDQuOTg5LjE4NS0uMTg1LTQuOTczeiIvPjxwYXRoIGQ9Ik0tOTYuNjEyIDI2MC45NjNsLjAwMS42OTRhMTM5LjU3IDEzOS41NyAwIDAxLTM2LjU1OC0yMC4wNzV2LS44MmExMzguOTMgMTM4LjkzIDAgMDAzNi41NTcgMjAuMnptMTI5LjI1NC0xNy4yOThsLS4wMDIuODAxQTEzOS41IDEzOS41IDAgMDEuNjU1IDI2MS40MTR2LS42OTVhMTM4Ljg1NiAxMzguODU2IDAgMDAzMS45ODctMTcuMDU0ek0tMTg3LjM0OCAxMzFjMCA3Ljc1NS42NCAxNS4zNiAxLjg2OSAyMi43NjhoLS42NjJBMTM5LjY5NiAxMzkuNjk2IDAgMDEtMTg4IDEzMWMwLTYuODQ5LjQ5Ni0xMy41ODIgMS40NTUtMjAuMTY2aC42NjhhMTM4LjY1MyAxMzguNjUzIDAgMDAtMS40NyAyMC4xNjZ6bTI3OC43MzcgMGMwIDcuNzU0LS42MzYgMTUuMzYtMS44NiAyMi43NjhoLS42NjFBMTM5LjAzNCAxMzkuMDM0IDAgMDA5MC43MzYgMTMxYzAtNi44MjktLjUtMTMuNTY0LTEuNDctMjAuMTY1aC42NjhBMTM5Ljg4IDEzOS44OCAwIDAxOTEuMzg5IDEzMXpNLTk2LjYxMS4zNDR2LjY5M2wtLjMyNS4xMmExMzkuMDg2IDEzOS4wODYgMCAwMC0yNS43NyAxMi43NTVoLTEuMTgyQTEzOS4zODYgMTM5LjM4NiAwIDAxLTk3LjE2NC41NDdsLjU1My0uMjA0ek0uNjUzLjU4N0wuMDI0LjM1MmExMzkuMzc3IDEzOS4zNzcgMCAwMTI3LjI1NSAxMy41NmgtMS4xODRBMTM5LjA0NSAxMzkuMDQ1IDAgMDAuNjU1IDEuMjhMLjY1Mi41ODd6Ii8+PC9nPjwvc3ZnPg==) no-repeat 0 0;
                position: absolute;
                left: 76px;
                top: 35px
            }
        }

        &:nth-child(2) {
            top: -95px;
            left: 238px;

            &:after {
                content: "";
                width: 9px;
                height: 45px;
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI0NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJncmF5IiBmaWxsLXJ1bGU9Im5vbnplcm8iPjxwYXRoIGQ9Ik0xLjA1IDM5LjUwNWEuMzI3LjMyNyAwIDAxLjQxNS4wNTdsLjAzNS4wNDcgMi40MjggMy44NzEgMy44ODUtMi40MTlhLjMyNy4zMjcgMCAwMS40MTQuMDU3bC4wMzYuMDQ3YS4zMjUuMzI1IDAgMDEtLjA1Ny40MTNsLS4wNDcuMDM1LTQuMTYyIDIuNTkxYS4zMjcuMzI3IDAgMDEtLjQxNC0uMDU2bC0uMDM2LS4wNDctMi42LTQuMTQ4YS4zMjUuMzI1IDAgMDEuMTA0LS40NDh6Ii8+PHBhdGggZD0iTS0xODIuMDM0IDE1MC45NjN2LjY5NGExMzkuNTcgMTM5LjU3IDAgMDEtMzYuNTU3LTIwLjA3NXYtLjgyYTEzOC45MyAxMzguOTMgMCAwMDM2LjU1NyAyMC4yem0xMjkuMjUzLTE3LjI5OGwtLjAwMi44MDFhMTM5LjUgMTM5LjUgMCAwMS0zMS45ODUgMTYuOTQ4di0uNjk1YTEzOC44NTYgMTM4Ljg1NiAwIDAwMzEuOTg3LTE3LjA1NHpNLTI3Mi43NyAyMWMwIDcuNzU1LjY0IDE1LjM2IDEuODY5IDIyLjc2OGgtLjY2MkExMzkuNjk2IDEzOS42OTYgMCAwMS0yNzMuNDIzIDIxYzAtNi44NDkuNDk3LTEzLjU4MiAxLjQ1Ni0yMC4xNjZoLjY2OEExMzguNjUzIDEzOC42NTMgMCAwMC0yNzIuNzcgMjF6TTUuOTY2IDIxYzAgNy43NTQtLjYzNiAxNS4zNi0xLjg1OSAyMi43NjhoLS42NjJBMTM5LjAzNCAxMzkuMDM0IDAgMDA1LjMxNCAyMWMwLTYuODI5LS41LTEzLjU2NC0xLjQ3LTIwLjE2NWguNjY3QTEzOS44OCAxMzkuODggMCAwMTUuOTY2IDIxem0tMTg4LTEzMC42NTZ2LjY5M2wtLjMyNC4xMmExMzkuMDg2IDEzOS4wODYgMCAwMC0yNS43NzEgMTIuNzU1aC0xLjE4MmExMzkuMzg2IDEzOS4zODYgMCAwMTI2LjcyNC0xMy4zNjRsLjU1NC0uMjA0em05Ny4yNjQuMjQzbC0uNjI4LS4yMzVhMTM5LjM3NyAxMzkuMzc3IDAgMDEyNy4yNTUgMTMuNTZoLTEuMTg0YTEzOS4wNDUgMTM5LjA0NSAwIDAwLTI1LjQ0MS0xMi42MzJsLS4wMDItLjY5M3oiLz48L2c+PC9zdmc+) no-repeat 0 0;
                position: absolute;
                left: 45px;
                top: 81px
            }
        }

        &:nth-child(3) {
            top: 40px;
            left: 236px;

            &:after {
                content: "";
                width: 33px;
                height: 21px;
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMi4wMzkgMTMuMjA4Yy4xNTEuMDUyLjI0LjIwNC4yMTYuMzU3bC0uMDE0LjA1N0wuNzUgMTcuOTM4bDQuMzMgMS40ODZjLjE1Mi4wNTIuMjQuMjA0LjIxNy4zNTdsLS4wMTQuMDU3YS4zMjcuMzI3IDAgMDEtLjM1Ny4yMTVsLS4wNTgtLjAxNC00LjY0LTEuNTkyYS4zMjUuMzI1IDAgMDEtLjIxNi0uMzU2bC4wMTQtLjA1NyAxLjU5OC00LjYyNGEuMzI3LjMyNyAwIDAxLjQxNS0uMjAyeiIvPjxwYXRoIGQ9Ik0tOTYuNjEyIDE3Ljk2M2wuMDAxLjY5NGExMzkuNTcgMTM5LjU3IDAgMDEtMzYuNTU4LTIwLjA3NXYtLjgyYTEzOC45MyAxMzguOTMgMCAwMDM2LjU1NyAyMC4yek0zMi42NDIuNjY1bC0uMDAyLjgwMUExMzkuNSAxMzkuNSAwIDAxLjY1NSAxOC40MTR2LS42OTVBMTM4Ljg1NiAxMzguODU2IDAgMDAzMi42NDIuNjY1ek0tMTg3LjM0OC0xMTJjMCA3Ljc1NS42NCAxNS4zNiAxLjg2OSAyMi43NjhoLS42NjJBMTM5LjY5NiAxMzkuNjk2IDAgMDEtMTg4LTExMmMwLTYuODQ5LjQ5Ni0xMy41ODIgMS40NTUtMjAuMTY2aC42NjhhMTM4LjY1MyAxMzguNjUzIDAgMDAtMS40NyAyMC4xNjZ6bTI3OC43MzcgMGMwIDcuNzU0LS42MzYgMTUuMzYtMS44NiAyMi43NjhoLS42NjFBMTM5LjAzNCAxMzkuMDM0IDAgMDA5MC43MzYtMTEyYzAtNi44MjktLjUtMTMuNTY0LTEuNDctMjAuMTY1aC42NjhBMTM5Ljg4IDEzOS44OCAwIDAxOTEuMzg5LTExMnptLTE4OC0xMzAuNjU2di42OTNsLS4zMjUuMTJhMTM5LjA4NiAxMzkuMDg2IDAgMDAtMjUuNzcgMTIuNzU1aC0xLjE4MmExMzkuMzg2IDEzOS4zODYgMCAwMTI2LjcyNC0xMy4zNjRsLjU1My0uMjA0em05Ny4yNjQuMjQzbC0uNjI5LS4yMzVhMTM5LjM3NyAxMzkuMzc3IDAgMDEyNy4yNTUgMTMuNTZoLTEuMTg0QTEzOS4wNDUgMTM5LjA0NSAwIDAwLjY1NS0yNDEuNzJsLS4wMDItLjY5M3oiLz48L2c+PC9zdmc+) no-repeat 0 0;
                position: absolute;
                left: -40px;
                top: 77px
            }
        }

        &:nth-child(4) {
            top: 120px;
            left: 127px;

            &:after {
                content: "";
                width: 38px;
                height: 22px;
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNNS45OTYuNTM5YS4zMjYuMzI2IDAgMDEtLjI3My4zMTVsLS4wNTkuMDA0LTQuNTgtLjA4LS4wOCA0LjU2NWEuMzI2LjMyNiAwIDAxLS4yNzQuMzE1bC0uMDU5LjAwNGEuMzI2LjMyNiAwIDAxLS4zMTYtLjI3MkwuMzUgNS4zMzEuNDM2LjQ0MkMuNDQuMjgyLjU1Ni4xNTIuNzEuMTI3TC43NjguMTIzbDQuOTA3LjA4NWMuMTguMDAzLjMyNC4xNTEuMzIxLjMzeiIvPjxwYXRoIGQ9Ik0zNy4zODggMjAuOTYzbC4wMDEuNjk0QTEzOS41NyAxMzkuNTcgMCAwMS44MzEgMS41ODJ2LS44MmExMzguOTMgMTM4LjkzIDAgMDAzNi41NTcgMjAuMnpNMTY2LjY0MiAzLjY2NWwtLjAwMi44MDFhMTM5LjUgMTM5LjUgMCAwMS0zMS45ODUgMTYuOTQ4di0uNjk1YTEzOC44NTYgMTM4Ljg1NiAwIDAwMzEuOTg3LTE3LjA1NHpNLTUzLjM0OC0xMDljMCA3Ljc1NS42NCAxNS4zNiAxLjg2OSAyMi43NjhoLS42NjJBMTM5LjY5NiAxMzkuNjk2IDAgMDEtNTQtMTA5YzAtNi44NDkuNDk2LTEzLjU4MiAxLjQ1NS0yMC4xNjZoLjY2OEExMzguNjUzIDEzOC42NTMgMCAwMC01My4zNDctMTA5em0yNzguNzM3IDBjMCA3Ljc1NC0uNjM2IDE1LjM2LTEuODYgMjIuNzY4aC0uNjYxQTEzOS4wMzQgMTM5LjAzNCAwIDAwMjI0LjczNi0xMDljMC02LjgyOS0uNS0xMy41NjQtMS40Ny0yMC4xNjVoLjY2OEExMzkuODggMTM5Ljg4IDAgMDEyMjUuMzg5LTEwOXptLTE4OC0xMzAuNjU2di42OTNsLS4zMjUuMTJhMTM5LjA4NiAxMzkuMDg2IDAgMDAtMjUuNzcgMTIuNzU1aC0xLjE4MmExMzkuMzg2IDEzOS4zODYgMCAwMTI2LjcyNC0xMy4zNjRsLjU1My0uMjA0em05Ny4yNjQuMjQzbC0uNjI5LS4yMzVhMTM5LjM3NyAxMzkuMzc3IDAgMDEyNy4yNTUgMTMuNTZoLTEuMTg0YTEzOS4wNDUgMTM5LjA0NSAwIDAwLTI1LjQ0LTEyLjYzMmwtLjAwMi0uNjkzeiIvPjwvZz48L3N2Zz4=) no-repeat 0 0;
                position: absolute;
                left: -58px;
                top: -10px
            }
        }

        &:nth-child(5) {
            top: 40px;
            left: 8px;

            &:after {
                content: "";
                width: 9px;
                height: 44px;
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI0NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJncmF5IiBmaWxsLXJ1bGU9Im5vbnplcm8iPjxwYXRoIGQ9Ik05NC4zODggMTUwLjk2M2wuMDAxLjY5NGExMzkuNTcgMTM5LjU3IDAgMDEtMzYuNTU4LTIwLjA3NXYtLjgyYTEzOC45MyAxMzguOTMgMCAwMDM2LjU1NyAyMC4yem0xMjkuMjU0LTE3LjI5OGwtLjAwMi44MDFhMTM5LjUgMTM5LjUgMCAwMS0zMS45ODUgMTYuOTQ4di0uNjk1YTEzOC44NTYgMTM4Ljg1NiAwIDAwMzEuOTg3LTE3LjA1NHpNMy42NTIgMjFjMCA3Ljc1NS42NCAxNS4zNiAxLjg2OSAyMi43NjhoLS42NjJBMTM5LjY5NiAxMzkuNjk2IDAgMDEzIDIxQzMgMTQuMTUxIDMuNDk2IDcuNDE4IDQuNDU1LjgzNGguNjY4QTEzOC42NTMgMTM4LjY1MyAwIDAwMy42NTMgMjF6bTI3OC43MzcgMGMwIDcuNzU0LS42MzYgMTUuMzYtMS44NiAyMi43NjhoLS42NjFBMTM5LjAzNCAxMzkuMDM0IDAgMDAyODEuNzM2IDIxYzAtNi44MjktLjUtMTMuNTY0LTEuNDctMjAuMTY1aC42NjhBMTM5Ljg4IDEzOS44OCAwIDAxMjgyLjM4OSAyMXptLTE4OC0xMzAuNjU2di42OTNsLS4zMjUuMTJhMTM5LjA4NiAxMzkuMDg2IDAgMDAtMjUuNzcgMTIuNzU1aC0xLjE4MmExMzkuMzg2IDEzOS4zODYgMCAwMTI2LjcyNC0xMy4zNjRsLjU1My0uMjA0em05Ny4yNjQuMjQzbC0uNjI5LS4yMzVhMTM5LjM3NyAxMzkuMzc3IDAgMDEyNy4yNTUgMTMuNTZoLTEuMTg0YTEzOS4wNDUgMTM5LjA0NSAwIDAwLTI1LjQ0LTEyLjYzMmwtLjAwMi0uNjkzeiIvPjxwYXRoIGQ9Ik04LjE0MiA0LjU2NmEuMzI3LjMyNyAwIDAxLS40MTctLjAyN2wtLjAzOS0uMDQ1TDQuOTk0LjgwMSAxLjI5IDMuNDg0YS4zMjcuMzI3IDAgMDEtLjQxNy0uMDI4bC0uMDM5LS4wNDRhLjMyNS4zMjUgMCAwMS4wMjgtLjQxNWwuMDQ1LS4wNEw0Ljg3NS4wODVhLjMyNy4zMjcgMCAwMS40MTcuMDI3bC4wMzkuMDQ1IDIuODgzIDMuOTU2YS4zMjUuMzI1IDAgMDEtLjA3Mi40NTR6Ii8+PC9nPjwvc3ZnPg==) no-repeat 0 0;
                position: absolute;
                left: -1px;
                top: -53px
            }
        }

        &:nth-child(6) {
            top: -95px;
            left: 20px;

            &:after {
                content: "";
                width: 28px;
                height: 16px;
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMjUuNjE1IDcuNTQzYS4zMjUuMzI1IDAgMDEtLjIxNy0uMzU2bC4wMTQtLjA1NyAxLjQ5Mi00LjMxNi00LjMzMi0xLjQ4N2EuMzI1LjMyNSAwIDAxLS4yMTYtLjM1NmwuMDE0LS4wNTdhLjMyNy4zMjcgMCAwMS4zNTgtLjIxNmwuMDU3LjAxNCA0LjY0IDEuNTkyYy4xNTEuMDUyLjI0LjIwNC4yMTYuMzU3bC0uMDE0LjA1Ny0xLjU5OCA0LjYyNGEuMzI3LjMyNyAwIDAxLS40MTQuMjAxeiIvPjxwYXRoIGQ9Ik0yNy4zODggMjYyLjk2M2wuMDAxLjY5NGExMzkuNTcgMTM5LjU3IDAgMDEtMzYuNTU4LTIwLjA3NXYtLjgyYTEzOC45MyAxMzguOTMgMCAwMDM2LjU1NyAyMC4yem0xMjkuMjU0LTE3LjI5OGwtLjAwMi44MDFhMTM5LjUgMTM5LjUgMCAwMS0zMS45ODUgMTYuOTQ4di0uNjk1YTEzOC44NTYgMTM4Ljg1NiAwIDAwMzEuOTg3LTE3LjA1NHpNLTYzLjM0OCAxMzNjMCA3Ljc1NS42NCAxNS4zNiAxLjg2OSAyMi43NjhoLS42NjJBMTM5LjY5NiAxMzkuNjk2IDAgMDEtNjQgMTMzYzAtNi44NDkuNDk2LTEzLjU4MiAxLjQ1NS0yMC4xNjZoLjY2OEExMzguNjUzIDEzOC42NTMgMCAwMC02My4zNDcgMTMzem0yNzguNzM3IDBjMCA3Ljc1NC0uNjM2IDE1LjM2LTEuODYgMjIuNzY4aC0uNjYxQTEzOS4wMzQgMTM5LjAzNCAwIDAwMjE0LjczNiAxMzNjMC02LjgyOS0uNS0xMy41NjQtMS40Ny0yMC4xNjVoLjY2OEExMzkuODggMTM5Ljg4IDAgMDEyMTUuMzg5IDEzM3ptLTE4OC0xMzAuNjU2di42OTNsLS4zMjUuMTJhMTM5LjA4NiAxMzkuMDg2IDAgMDAtMjUuNzcgMTIuNzU1SC4xMTFBMTM5LjM4NiAxMzkuMzg2IDAgMDEyNi44MzYgMi41NDdsLjU1My0uMjA0em05Ny4yNjQuMjQzbC0uNjI5LS4yMzVhMTM5LjM3NyAxMzkuMzc3IDAgMDEyNy4yNTUgMTMuNTZoLTEuMTg0YTEzOS4wNDUgMTM5LjA0NSAwIDAwLTI1LjQ0LTEyLjYzMmwtLjAwMi0uNjkzeiIvPjwvZz48L3N2Zz4=) no-repeat 0 0;
                position: absolute;
                left: 55px;
                top: -36px
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .Hire_List {
        width: 552px;
        height: 550px;

        .Hire_List_Title {
            font-size: 1rem;
            line-height: 1.1rem;
            letter-spacing: .1px
        }

        .Hire_List_Desc {
            font-size: 1rem;
            line-height: 1.5rem;
            color: #b2b2b2;
            display: block;
            margin: 0;
            opacity: 0
        }

        &:after {
            width: 914px;
            height: 892px;
        }

        li {
            &:first-child {
                top: 29px;
                left: 218px;

                &:after {
                    width: 43px;
                    height: 25px;
                    left: 135px;
                    top: 31px;
                    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDMiIGhlaWdodD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNNDAuODkgMTQuOTU4YS41LjUgMCAwMS45ODgtLjEyN2wuMDExLjA5LjMwMyA4LjE4MmEuNS41IDAgMDEtLjQyMy41MTNsLS4wOTUuMDA1LTguMTgyLS4zMDNhLjUuNSAwIDAxLS4wNTMtLjk5NWwuMDktLjAwNCA3LjY0NC4yODMtLjI4My03LjY0NHoiLz48cGF0aCBkPSJNLTE0Ny4wMDEgNDAyLjc5NGwuMDAxIDEuMDY3QTIxMy41ODggMjEzLjU4OCAwIDAxLTIwMy4wMDMgMzczdi0xLjI2MUEyMTIuNjEzIDIxMi42MTMgMCAwMC0xNDcgNDAyLjc5NHptMTk4LjAwNS0yNi41OTJsLS4wMDIgMS4yMzFhMjEzLjQ1IDIxMy40NSAwIDAxLTQ4Ljk5OSAyNi4wNTV2LTEuMDY5YTIxMi40NyAyMTIuNDcgMCAwMDQ5LjAwMS0yNi4yMTd6TS0yODYgMjAzYzAgMTEuOTIxLjk4IDIzLjYxNCAyLjg2MiAzNS4wMDJoLTEuMDE0QTIxNS40OTYgMjE1LjQ5NiAwIDAxLTI4NyAyMDNjMC0xMC41MjkuNzYtMjAuODggMi4yMy0zMWgxLjAyM0EyMTMuODkgMjEzLjg5IDAgMDAtMjg2IDIwM3ptNDI3IDBjMCAxMS45Mi0uOTc1IDIzLjYxMi0yLjg0OCAzNS4wMDJoLTEuMDE0QTIxNC40NzUgMjE0LjQ3NSAwIDAwMTQwIDIwM2MwLTEwLjQ5OC0uNzY3LTIwLjg1Mi0yLjI1My0zMWwxLjAyNC0uMDAxQTIxNS43ODMgMjE1Ljc4MyAwIDAxMTQxIDIwM3pNLTE0NyAyLjE0MXYxLjA2NWwtLjQ5Ny4xODVBMjEyLjc2NSAyMTIuNzY1IDAgMDAtMTg2Ljk3NyAyM2wtMS44MS0uMDAyYTIxMy4yMzIgMjEzLjIzMiAwIDAxNDAuOTQtMjAuNTQ0bC44NDctLjMxM3pNMiAyLjUxNWwtLjk2My0uMzYyQTIxMy4yMTUgMjEzLjIxNSAwIDAxNDIuNzkgMjNsLTEuODEzLS4wMDFBMjEyLjcwNSAyMTIuNzA1IDAgMDAyLjAwMyAzLjU4TDIgMi41MTV6Ii8+PC9nPjwvc3ZnPg==) no-repeat 0 0
                }
            }

            &:nth-child(2) {
                top: 104px;
                right: -59px;

                &:after {
                    width: 12px;
                    height: 68px;
                    left: 238px;
                    top: 127px;
                    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNLjQ3IDYwLjQ0OGEuNS41IDAgMDEuNjM0LjA4N2wuMDU0LjA3MiAzLjcyIDUuOTUyIDUuOTUtMy43MThhLjUuNSAwIDAxLjYzNS4wODdsLjA1NC4wNzJhLjUuNSAwIDAxLS4wODcuNjM0bC0uMDcyLjA1NS02LjM3NSAzLjk4M2EuNS41IDAgMDEtLjYzNC0uMDg3bC0uMDU1LS4wNzJMLjMxIDYxLjEzN2EuNS41IDAgMDEuMTYtLjY4OXoiLz48cGF0aCBkPSJNLTI4MC4wMDEgMjMxLjc5NGwuMDAxIDEuMDY3QTIxMy41ODggMjEzLjU4OCAwIDAxLTMzNi4wMDMgMjAydi0xLjI2MUEyMTIuNjEzIDIxMi42MTMgMCAwMC0yODAgMjMxLjc5NHptMTk4LjAwNS0yNi41OTJsLS4wMDIgMS4yMzFhMjEzLjQ1IDIxMy40NSAwIDAxLTQ4Ljk5OSAyNi4wNTV2LTEuMDY5YTIxMi40NyAyMTIuNDcgMCAwMDQ5LjAwMS0yNi4yMTd6TS00MTkgMzJjMCAxMS45MjEuOTggMjMuNjE0IDIuODYyIDM1LjAwMmgtMS4wMTRBMjE1LjQ5NiAyMTUuNDk2IDAgMDEtNDIwIDMyYzAtMTAuNTI5Ljc2LTIwLjg4IDIuMjMtMzFoMS4wMjNBMjEzLjg5IDIxMy44OSAwIDAwLTQxOSAzMnpNOCAzMmMwIDExLjkyLS45NzUgMjMuNjEyLTIuODQ4IDM1LjAwMkg0LjEzOEEyMTQuNDc1IDIxNC40NzUgMCAwMDcgMzJjMC0xMC40OTgtLjc2Ny0yMC44NTItMi4yNTMtMzFMNS43NzEuOTk5QTIxNS43ODMgMjE1Ljc4MyAwIDAxOCAzMnptLTI4OC0yMDAuODU5djEuMDY1bC0uNDk3LjE4NUEyMTIuNzY1IDIxMi43NjUgMCAwMC0zMTkuOTc3LTE0OGwtMS44MS0uMDAyYTIxMy4yMzIgMjEzLjIzMiAwIDAxNDAuOTQtMjAuNTQ0bC44NDctLjMxM3ptMTQ5IC4zNzRsLS45NjMtLjM2MkEyMTMuMjE1IDIxMy4yMTUgMCAwMS05MC4yMS0xNDhsLTEuODEzLS4wMDFhMjEyLjcwNSAyMTIuNzA1IDAgMDAtMzguOTczLTE5LjQxOWwtLjAwMy0xLjA2NXoiLz48L2c+PC9zdmc+) no-repeat 0 0
                }
            }

            &:nth-child(3) {
                right: -135px;
                top: 321px;

                &:after {
                    width: 51px;
                    height: 30px;
                    left: 108px;
                    top: 115px;
                    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEiIGhlaWdodD0iMzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMy4xMjMgMTkuNDg0YS41LjUgMCAwMS4zMzIuNTQ4bC0uMDIyLjA4OC0yLjI4NSA2LjYzNSA2LjYzNiAyLjI4NWEuNS41IDAgMDEuMzMxLjU0OGwtLjAyMi4wODhhLjUuNSAwIDAxLS41NDcuMzMxbC0uMDg4LS4wMjFMLjM1IDI3LjUzOGEuNS41IDAgMDEtLjMzMi0uNTQ4bC4wMjItLjA4NyAyLjQ0OC03LjEwOWEuNS41IDAgMDEuNjM1LS4zMXoiLz48cGF0aCBkPSJNLTE0OC4wMDEgMjYuNzk0bC4wMDEgMS4wNjdBMjEzLjU4OCAyMTMuNTg4IDAgMDEtMjA0LjAwMy0zdi0xLjI2MUEyMTIuNjEzIDIxMi42MTMgMCAwMC0xNDggMjYuNzk0ek01MC4wMDQuMjAybC0uMDAyIDEuMjMxQTIxMy40NSAyMTMuNDUgMCAwMTEuMDAzIDI3LjQ4OHYtMS4wNjlBMjEyLjQ3IDIxMi40NyAwIDAwNTAuMDA0LjIwMnpNLTI4Ny0xNzNjMCAxMS45MjEuOTggMjMuNjE0IDIuODYyIDM1LjAwMmgtMS4wMTRBMjE1LjQ5NiAyMTUuNDk2IDAgMDEtMjg4LTE3M2MwLTEwLjUyOS43Ni0yMC44OCAyLjIzLTMxaDEuMDIzQTIxMy44OSAyMTMuODkgMCAwMC0yODctMTczem00MjcgMGMwIDExLjkyLS45NzUgMjMuNjEyLTIuODQ4IDM1LjAwMmgtMS4wMTRBMjE0LjQ3NSAyMTQuNDc1IDAgMDAxMzktMTczYzAtMTAuNDk4LS43NjctMjAuODUyLTIuMjUzLTMxbDEuMDI0LS4wMDFBMjE1Ljc4MyAyMTUuNzgzIDAgMDExNDAtMTczem0tMjg4LTIwMC44NTl2MS4wNjVsLS40OTcuMTg1QTIxMi43NjUgMjEyLjc2NSAwIDAwLTE4Ny45NzctMzUzbC0xLjgxLS4wMDJhMjEzLjIzMiAyMTMuMjMyIDAgMDE0MC45NC0yMC41NDRsLjg0Ny0uMzEzem0xNDkgLjM3NGwtLjk2My0uMzYyQTIxMy4yMTUgMjEzLjIxNSAwIDAxNDEuNzktMzUzbC0xLjgxMy0uMDAxQTIxMi43MDUgMjEyLjcwNSAwIDAwMS4wMDMtMzcyLjQyTDEtMzczLjQ4NXoiLz48L2c+PC9zdmc+) no-repeat 0 0
                }
            }

            &:nth-child(4) {
                top: 464px;
                left: 215px;

                &:after {
                    width: 58px;
                    height: 34px;
                    left: -66px;
                    top: -34px;
                    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iMzQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNOC45MDkgMS4zOTZhLjUuNSAwIDAxLS40MTkuNDg0bC0uMDkuMDA3LTcuMDE2LS4xMjMtLjEyNCA3LjAxN2EuNS41IDAgMDEtLjQxOC40ODRsLS4wOS4wMDdhLjUuNSAwIDAxLS40ODUtLjQxOWwtLjAwNi0uMDkuMTMtNy41MTZhLjUuNSAwIDAxLjQyLS40ODVMLjkuNzU2bDcuNTE3LjEzMWEuNS41IDAgMDEuNDkuNTA5eiIvPjxwYXRoIGQ9Ik01Ni45OTkgMzIuNzk0TDU3IDMzLjg2QTIxMy41ODggMjEzLjU4OCAwIDAxLjk5NyAzVjEuNzM5QTIxMi42MTMgMjEyLjYxMyAwIDAwNTcgMzIuNzk0ek0yNTUuMDA0IDYuMjAybC0uMDAyIDEuMjMxYTIxMy40NSAyMTMuNDUgMCAwMS00OC45OTkgMjYuMDU1di0xLjA2OWEyMTIuNDcgMjEyLjQ3IDAgMDA0OS4wMDEtMjYuMjE3ek0tODItMTY3YzAgMTEuOTIxLjk4IDIzLjYxNCAyLjg2MiAzNS4wMDJoLTEuMDE0QTIxNS40OTYgMjE1LjQ5NiAwIDAxLTgzLTE2N2MwLTEwLjUyOS43Ni0yMC44OCAyLjIzLTMxaDEuMDIzQTIxMy44OSAyMTMuODkgMCAwMC04Mi0xNjd6bTQyNyAwYzAgMTEuOTItLjk3NSAyMy42MTItMi44NDggMzUuMDAyaC0xLjAxNEEyMTQuNDc1IDIxNC40NzUgMCAwMDM0NC0xNjdjMC0xMC40OTgtLjc2Ny0yMC44NTItMi4yNTMtMzFsMS4wMjQtLjAwMUEyMTUuNzgzIDIxNS43ODMgMCAwMTM0NS0xNjd6TTU3LTM2Ny44NTl2MS4wNjVsLS40OTcuMTg1QTIxMi43NjUgMjEyLjc2NSAwIDAwMTcuMDIzLTM0N2wtMS44MS0uMDAyYTIxMy4yMzIgMjEzLjIzMiAwIDAxNDAuOTQtMjAuNTQ0bC44NDctLjMxM3ptMTQ5IC4zNzRsLS45NjMtLjM2MkEyMTMuMjE1IDIxMy4yMTUgMCAwMTI0Ni43OS0zNDdsLTEuODEzLS4wMDFhMjEyLjcwNSAyMTIuNzA1IDAgMDAtMzguOTczLTE5LjQxOWwtLjAwMy0xLjA2NXoiLz48L2c+PC9zdmc+) no-repeat 0 0
                }
            }

            &:nth-child(5) {
                left: 8px;
                top: 321px;

                &:after {
                    width: 12px;
                    height: 68px;
                    left: 55px;
                    top: -92px;
                    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMTQzLjk5OSAyMzIuNzk0bC4wMDEgMS4wNjdBMjEzLjU4OCAyMTMuNTg4IDAgMDE4Ny45OTcgMjAzdi0xLjI2MUEyMTIuNjEzIDIxMi42MTMgMCAwMDE0NCAyMzIuNzk0em0xOTguMDA1LTI2LjU5MmwtLjAwMiAxLjIzMWEyMTMuNDUgMjEzLjQ1IDAgMDEtNDguOTk5IDI2LjA1NXYtMS4wNjlhMjEyLjQ3IDIxMi40NyAwIDAwNDkuMDAxLTI2LjIxN3pNNSAzM2MwIDExLjkyMS45OCAyMy42MTQgMi44NjIgMzUuMDAySDYuODQ4QTIxNS40OTYgMjE1LjQ5NiAwIDAxNCAzM2MwLTEwLjUyOS43Ni0yMC44OCAyLjIzLTMxaDEuMDIzQTIxMy44OSAyMTMuODkgMCAwMDUgMzN6bTQyNyAwYzAgMTEuOTItLjk3NSAyMy42MTItMi44NDggMzUuMDAyaC0xLjAxNEEyMTQuNDc1IDIxNC40NzUgMCAwMDQzMSAzM2MwLTEwLjQ5OC0uNzY3LTIwLjg1Mi0yLjI1My0zMWwxLjAyNC0uMDAxQTIxNS43ODMgMjE1Ljc4MyAwIDAxNDMyIDMzek0xNDQtMTY3Ljg1OXYxLjA2NWwtLjQ5Ny4xODVBMjEyLjc2NSAyMTIuNzY1IDAgMDAxMDQuMDIzLTE0N2wtMS44MS0uMDAyYTIxMy4yMzIgMjEzLjIzMiAwIDAxNDAuOTQtMjAuNTQ0bC44NDctLjMxM3ptMTQ5IC4zNzRsLS45NjMtLjM2MkEyMTMuMjE1IDIxMy4yMTUgMCAwMTMzMy43OS0xNDdsLTEuODEzLS4wMDFhMjEyLjcwNSAyMTIuNzA1IDAgMDAtMzguOTczLTE5LjQxOWwtLjAwMy0xLjA2NXoiLz48cGF0aCBkPSJNMTEuMjEgOC4zNTlhLjUuNSAwIDAxLS42NC0uMDQzbC0uMDU4LS4wNjgtNC4xMjYtNS42NzdMLjcxIDYuNjk1YS41LjUgMCAwMS0uNjM5LS4wNDNsLS4wNi0uMDY3YS41LjUgMCAwMS4wNDMtLjY0bC4wNjgtLjA1OSA2LjA4Mi00LjQxOWEuNS41IDAgMDEuNjQuMDQzbC4wNTguMDY4IDQuNDIgNi4wODNhLjUuNSAwIDAxLS4xMTEuNjk4eiIvPjwvZz48L3N2Zz4=) no-repeat 0 0
                }
            }

            &:nth-child(6) {
                left: 58px;
                top: 107px;

                &:after {
                    width: 43px;
                    height: 25px;
                    left: 106px;
                    top: -48px;
                    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDMiIGhlaWdodD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMzkuMjgyIDExLjEzNGEuNS41IDAgMDEtLjMzMi0uNTQ4bC4wMjItLjA4OCAyLjI4NS02LjYzNS02LjYzNS0yLjI4NWEuNS41IDAgMDEtLjMzMi0uNTQ4bC4wMjItLjA4OGEuNS41IDAgMDEuNTQ4LS4zMzFsLjA4Ny4wMjEgNy4xMDggMi40NDhhLjUuNSAwIDAxLjMzMi41NDhsLS4wMjIuMDg3LTIuNDQ4IDcuMTA5YS41LjUgMCAwMS0uNjM1LjMxeiIvPjxwYXRoIGQ9Ik00MS45OTkgNDAzLjc5NEw0MiA0MDQuODZBMjEzLjU4OCAyMTMuNTg4IDAgMDEtMTQuMDAzIDM3NHYtMS4yNjFBMjEyLjYxMyAyMTIuNjEzIDAgMDA0MiA0MDMuNzk0em0xOTguMDA1LTI2LjU5MmwtLjAwMiAxLjIzMWEyMTMuNDUgMjEzLjQ1IDAgMDEtNDguOTk5IDI2LjA1NXYtMS4wNjlhMjEyLjQ3IDIxMi40NyAwIDAwNDkuMDAxLTI2LjIxN3pNLTk3IDIwNGMwIDExLjkyMS45OCAyMy42MTQgMi44NjIgMzUuMDAyaC0xLjAxNEEyMTUuNDk2IDIxNS40OTYgMCAwMS05OCAyMDRjMC0xMC41MjkuNzYtMjAuODggMi4yMy0zMWgxLjAyM0EyMTMuODkgMjEzLjg5IDAgMDAtOTcgMjA0em00MjcgMGMwIDExLjkyLS45NzUgMjMuNjEyLTIuODQ4IDM1LjAwMmgtMS4wMTRBMjE0LjQ3NSAyMTQuNDc1IDAgMDAzMjkgMjA0YzAtMTAuNDk4LS43NjctMjAuODUyLTIuMjUzLTMxbDEuMDI0LS4wMDFBMjE1Ljc4MyAyMTUuNzgzIDAgMDEzMzAgMjA0ek00MiAzLjE0MXYxLjA2NWwtLjQ5Ny4xODVBMjEyLjc2NSAyMTIuNzY1IDAgMDAyLjAyMyAyNGwtMS44MS0uMDAyYTIxMy4yMzIgMjEzLjIzMiAwIDAxNDAuOTQtMjAuNTQ0TDQyIDMuMTR6bTE0OSAuMzc0bC0uOTYzLS4zNjJBMjEzLjIxNSAyMTMuMjE1IDAgMDEyMzEuNzkgMjRsLTEuODEzLS4wMDFBMjEyLjcwNSAyMTIuNzA1IDAgMDAxOTEuMDAzIDQuNThMMTkxIDMuNTE1eiIvPjwvZz48L3N2Zz4=) no-repeat 0 0
                }
            }
        }
    }


}

@keyframes spinIn {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) rotate(0);
        -webkit-filter: blur(.3);
        filter: blur(.3)
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%) rotate(4turn);
        -webkit-filter: blur(0);
        filter: blur(0)
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0)
    }

    85% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.1)
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1)
    }
}

@keyframes list {
    0% {
        opacity: 0;
        transform: translateY(10px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.G_List {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        padding: 0 0 0 20px;
        color: #b2b2b2;
        position: relative;
        font-size: 1.1rem;
        line-height: 1.6rem;
        letter-spacing: .1px;
        padding: 0 0 20px 23px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 11px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: linear-gradient(#00ebaa, #5e67df)
        }
    }
}

@media screen and (min-width: 992px) {
    .G_List {
        li {

            &:before {
                top: 15px
            }
        }
    }
}